<section class="container">
  <div class="header">
    <span class="logo">
      <img [src]="company?.logo" alt="Company logo"/>
    </span>
    <button *ngIf="backButton" type="button" class="btn" (click)="back()" [ngStyle]="{'background-color': company?.primaryColor}">{{ 'JOBS.PREVIEW.BACK_TO_EDIT' | translate}}</button>
  </div>
  <div class="title">
    <h1 class="title-text">{{job.jobInfo.jobTitle}}</h1>
    <div class="media">
      <div *ngIf="!!job.jobDetails.video; else showImage"
           class="ad-video">
        <div class="video-container">
          <video [poster]="videoPosterUrl" controls playsinline>
            <source [src]="videoMp4Url" />
          </video>
        </div>
      </div>
      <ng-template #showImage>
        <div class="image">
          <img [src]="job.jobDetails.picture">
        </div>
      </ng-template>
    </div>
  </div>
  <div class="information">
    <div class="information-content">
      <div class="description">
        <div>
          <div class="content ql-editor"
               [innerHTML]="(job.jobDetails.shortJobDescriptionTemplate | domSanitizer: 'html') || job.jobDetails.shortJobDescription"></div>
        </div>
        <div class="controls">
          <button (click)="toggleFullDescription()"
                  class="btn-link" [ngStyle]="{'color': company?.primaryColor}">
            <i class="icon-chevron-right" [ngStyle]="{'transform': fullDescriptionShown ? '' : 'none'}"></i>
            {{ (fullDescriptionShown ? 'BUTTONS.READ_LESS' : 'BUTTONS.READ_MORE') | translate }}
          </button>
        </div>
        <div [@expandAnimation]="fullDescriptionShown">
          <div>
            <div class="content ql-editor"
                 [innerHTML]="(job.jobDetails.jobDescriptionTemplate | domSanitizer: 'html') || job.jobDetails.jobDescription"></div>
          </div>
        </div>
      </div>
      <div class="specifics">
        <div class="details">
          <ul class="details-list">
            <li class="list-item">
              <span class="label">{{ 'SHARED.LOCATION' | translate }}</span>
              <span class="info">{{ locationName }}</span>
            </li>
            <li class="list-item">
              <span class="label">{{ 'JOBS.CATEGORY_LABEL' | translate }}</span>
              <span class="info">{{ categoryName }}</span>
            </li>
            <li class="list-item">
              <span class="label">{{ 'JOBS.TIME_EXTENT_LABEL' | translate }}</span>
              <span class="info">{{ typeOfEmploymentName }}</span>
            </li>
            <li class="list-item" *ngIf="job.jobInfo.typeOfEmploymentAddition">
              <span class="label">{{ 'JOBS.TYPE_OF_EMPLOYMENT_LABEL' | translate }}</span>
              <span class="info">{{ typeOfEmploymentAdditionName }}</span>
            </li>
            <li *ngIf="job.jobInfo.contactDetail"
                class="list-item">
              <span class="label">{{ 'JOBS.PREVIEW.CONTACT_LABEL' | translate }}</span>
              <span class="info">{{ responsiblePerson?.name }} <br />
                {{ responsiblePerson?.email }}</span>
            </li>
            <li class="list-item">
              <span class="label">{{ 'JOBS.PREVIEW.DEADLINE_LABEL' | translate }}</span>
              <span class="info">{{ job.jobInfo.applicationDate }}</span>
            </li>
            <li class="list-item" *ngIf="job.jobInfo.jobWorkLocation">
              <span class="label">{{ 'JOBS.WORK_LOCATION_LABEL' | translate }}</span>
              <span class="info">{{ jobWorkLocationName }}</span>
            </li>
          </ul>
        </div>
        <div class="share">
          <div class="share">
            <p class="instructions">
              {{ 'JOBS.PREVIEW.SHARE_ON_SOCIAL_MEDIA' | translate }}:
            </p>
            <span class="btn-icon">
              <i class="icon-facebook"></i>
            </span>
            <span class="btn-icon">
              <i class="icon-linked-in"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
