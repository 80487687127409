import { ApplicationDocument } from '../model/application.interface';
import { User, UserCommunicator } from '../model/user.interface';
import { IApplicationEmailResponse } from '../model/email-response.interface';
import { ENTERPRISE_ROLES } from '../resources/roles';

export class ApplicationEmailResponse {

  applicationDocuments: ApplicationDocument[];
  content: string;
  createdAt: Date;
  id: number;
  unreadResponse?: boolean;

  constructor(
    { readByCustomers, readByEnterpriseManagers, createdAt, id, applicationDocuments, content, readByEmailSender }: IApplicationEmailResponse,
    currentUser: User
  ) {
    this.applicationDocuments = applicationDocuments;
    this.content = content;
    this.createdAt = new Date(createdAt);
    this.id = id;

    if (readByEmailSender) {
      return;
    }

    if (ENTERPRISE_ROLES.includes(currentUser.role)) {
      this.unreadResponse = !readByEnterpriseManagers.find(({ email }: UserCommunicator) => email === currentUser.email);
    } else {
      this.unreadResponse = !readByCustomers.find(({ email }: UserCommunicator) => email === currentUser.email);
    }
  }
}
