<section class="main-section" [ngClass]="{'is-modal': mode === AGREEMENT_FORM_MODE.preview}">
  <div class="button-close-wrapper" *ngIf="mode === AGREEMENT_FORM_MODE.preview">
    <i class="icon-cancel-in-circle" (click)="closeModal()"></i>
  </div>
  <form [formGroup]="form">
    <header class="main-section-header">
      <h3 class="main-section-title" *ngIf="!isEditTitleVisible">
        {{ title.value || ('AGREEMENT_FORM.PAGE_TITLE_CREATE' | translate) }}
      </h3>
      <div *ngIf="isEditTitleVisible" class="form-group" [ngClass]="{'active' : isEditTitleVisible}">
        <input
        class="form-control"
        formControlName="title"
        [ngClass]="{
          'is-invalid': form.get('title').invalid && form.get('title').touched
        }" />
        <div class="invalid-feedback" *ngIf="form.get('title').errors?.maxlength">
            {{ 'GENERIC_ERROR_MESSAGES.MAX_LENGTH' | translate: { maxLength: maxLength } }}
        </div>
      </div>
      <span class="icon-wrapper" *ngIf="mode !== AGREEMENT_FORM_MODE.preview">
        <i class="icon icon-pencil transparent" (click)="editTitle()" [ngClass]="{'active' : isEditTitleVisible}"></i>
      </span>
    </header>

    <ul class="tabs" *ngIf="mode !== AGREEMENT_FORM_MODE.preview">
      <li *ngFor="let tab of tabs; let i = index" class="tab"
      (click)="selectTab(tab)"
      [ngClass]="
      {'active': tab.active,
      'first-is-active': tabs[0].active,
      'invalid': (i === 1 && dinamicForm.invalid && dinamicForm.touched)
        || (i === 0 && ((branch.invalid && branch.touched) || (terms.invalid && terms.touched) || (customersAndManagersAllowed.invalid && customersAndManagersAllowed.touched)))}">
        {{tab.tabTitle | translate}}
      </li>
    </ul>

    <div *ngIf="tabs[0].active || mode === AGREEMENT_FORM_MODE.preview" class="panel">

      <div *ngIf="mode === 'edit'" class="info-wrapper">
        <div class="info-section">
          <div class="agreement-form-info">
            <i class="icon-avatar icon"></i>
            <span class="info"> {{agreement?.creator.name}}
            </span>
          </div>
          <div class="agreement-form-info">
            <i class="icon-calendar icon"></i>
            <span class="info">
              {{ agreement?.createdAt | date:'dd.MM.yyyy' }}
            </span>
          </div>
          <div class="agreement-form-info">
            <i class="icon-branch icon"></i>
            <span class="info">
              {{ agreement?.branch.name }}
            </span>
          </div>
        </div>
        <div class="info-section">
          <p>{{ 'AGREEMENT_FORM.EDITED_BY_LABEL' | translate }}</p>
          <div class="history-wrapper scrollbar-style">
            <div class="history-container" *ngFor="let agreementForm of historyAgreementForm">
              <div class="agreement-form-info">
                <i class="icon-avatar icon"></i>
                <span class="info"> {{ agreementForm?.creator.name }}
                </span>
              </div>
              <div class="agreement-form-info">
                <i class="icon-calendar icon"></i>
                <span class="info">
                  {{ agreementForm.createdAt | date:'dd.MM.yyyy' }}
                </span>
              </div>
              <h3 class="preview" (click)="openAgreementFormPreview(agreementForm.content)">
                {{ 'BUTTONS.PREVIEW' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-quill-editor
            label="{{ 'AGREEMENT_FORM.TERMS_OF_AGREEMENT_LABEL' | translate }}"
            [form]="form"
            controlName='terms'
            [toolbarPositionBottom]="true">
          </app-quill-editor>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label>{{ 'JOBS.BRANCH_LABEL' | translate }}</label>
            <select [formControl]="branch"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': form.get('branch').invalid &&
                                    form.get('branch').touched
                    }"
                    (change)="setBranch()">
              <option disabled
                      selected
                      [ngValue]="null"
                      class="d-none"></option>
              <option *ngFor="let branch of branches"
                      [ngValue]="branch.id">{{ branch.name }}</option>
            </select>
            <div class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.SELECT_OPTION' | translate }}</div>
          </div>
        </div>

        <!-- CUSTOMERS ALLOWED -->
        <div class="col-12">
          <div class="form-group">
            <label>{{ 'AGREEMENT_FORM.USERS_THAT_MANIPULATE_AGREEMENT' | translate }}</label>

            <ng-select [items]="users"
                        [multiple]="true"
                        bindLabel="name"
                        [closeOnSelect]="false"
                        [notFoundText] = "'USER.NO_ITEMS_FOUND_MESSAGE' | translate"
                        [formControl]="customersAndManagersAllowed">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <label class="custom-checkbox">
                  <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" [disabled]="item$.selected"/>
                    {{item.name}}
                  <span class="checkmark  square"></span>
                </label>
              </ng-template>
            </ng-select>
          </div>
        </div>

         <!-- STATUS -->

        <div *ngIf="mode !== 'create'"
        class="col-12">
          <div class="form-group">
            <label>{{ 'AGREEMENT_FORM.STATUS_LABEL' | translate }}</label>
            <select formControlName="status"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': status.invalid && status.touched }">
              <option disabled
                      selected
                      [value]="null"
                      class="d-none"></option>
              <option value="active">{{ 'JOBS.ACTIVE' | translate }}</option>
              <option value="inactive">{{ 'JOBS.INACTIVE' | translate }}</option>
            </select>
            <div class="invalid-feedback">{{ 'GENERIC_ERROR_MESSAGES.SELECT_OPTION' | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="tabs[1].active || mode === AGREEMENT_FORM_MODE.preview" class="panel">
      <div class="dinamic-form-wrapper" formArrayName="dinamicForm">
          <div dragula="AGREEMENTFORMCONTROL" [(dragulaModel)]="dinamicForm.controls">
            <div class="dinamic-form col-md-6"  *ngFor="let control of dinamicForm.controls; let i = index; let last = last;">

              <div [formGroup]="control" class="controls-wrapper">
                <label class="custom-checkbox mt-3" *ngIf="control.get('elementType').value !== controlType.info">
                  <input formControlName="required"
                          type="checkbox"
                          [checked]="control.isRequired"/>
                  {{ 'AGREEMENT_FORM.IS_CONTROL_REQUIRED_LABEL' | translate }}
                  <span class="checkmark"></span>
                </label>

                <!-- LABEL -->

                <div class="form-group">
                  <input *ngIf="control.get('elementType').value !== controlType.info"
                          formControlName="elementLabel"
                          class="form-control"
                          placeholder="{{ 'AGREEMENT_FORM.EDIT_LABEL' | translate }}"
                          [ngClass]="{
                            'is-invalid': control.get('elementLabel').invalid && control.get('elementLabel').touched
                          }" />
                  <div class="invalid-feedback">
                    <ng-container *ngIf="control.get('elementLabel').errors?.required">
                      {{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}
                    </ng-container>
                    <ng-container *ngIf="control.get('elementLabel').errors?.maxlength">
                      {{ 'GENERIC_ERROR_MESSAGES.MAX_LENGTH' | translate: { maxLength: maxLength } }}
                    </ng-container>
                  </div>
                </div>

                <!-- EDITOR -->

                <div class="form-group" *ngIf="control.get('elementType').value === controlType.text">
                  <textarea
                    class="form-control"
                    formControlName="textFieldEditorValue"
                    rows="2">
                  </textarea>
                </div>

                <!-- SIGNATURE -->

                <div class="form-group" *ngIf="control.get('elementType').value === controlType.signature">
                  <textarea
                    class="form-control"
                    formControlName="values"
                    rows="2">
                  </textarea>
                </div>

                <!-- iNFO -->

                <app-quill-editor
                *ngIf="control.get('elementType').value === controlType.info && showEditor"
                  [form]="control"
                  controlName='values'
                  [toolbarPositionBottom]="true">
                </app-quill-editor>

                <!-- ADD-ITEM -->

                <div class="form-group"
                    *ngIf="control.get('elementType').value === controlType.dropdown">
                  <input formControlName="selectedValues"
                  class="form-control"
                  placeholder="{{ 'REQUISITION_FORM.ADD_ITEMS_IN_THE_LIST' | translate }}"
                  (keydown.enter)="addToList(control, $event)"
                  [ngClass]="{
                    'is-invalid': (control.get('values').invalid && control.get('values').touched)
                                    || (control.get('selectedValues').invalid && control.get('selectedValues').touched)}"/>
                  <div class="invalid-feedback">
                    <ng-container *ngIf="control.get('values').errors?.required">
                      {{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}
                    </ng-container>
                    <ng-container *ngIf="control.get('selectedValues').errors?.maxlength">
                      {{ 'GENERIC_ERROR_MESSAGES.MAX_LENGTH' | translate: { maxLength: maxLength } }}
                    </ng-container>
                  </div>
                  <div class="icon-plus-wrapper" (click)="addToList(control)">
                    <i class="icon-plus-in-circle"></i>
                  </div>
                </div>
                <ul class="item-list" *ngIf="control.get('values').value?.length && control.get('elementType').value === controlType.dropdown">
                  <li class="item" *ngFor="let item of control.get('values').value">
                    {{item}}
                  <div *ngIf="mode !== AGREEMENT_FORM_MODE.preview" class="icon-bin-list-wrapper" (click)="removeFromList(control, item)">
                    <i class="icon-bin"></i>
                  </div>
                  </li>
                </ul>
              </div>
              <div *ngIf="mode !== AGREEMENT_FORM_MODE.preview" class="icon-bin-wrapper" (click)="removeControl(i)">
                <i class="icon-bin"></i>
              </div>
             <div *ngIf="mode !== AGREEMENT_FORM_MODE.preview && isMobile && i !== 0" class="handle-drag-and-drop-wrapper up" (click)="moveItem(i, i-1)">
                <i class="icon-up"></i>
              </div>
              <div *ngIf="mode !== AGREEMENT_FORM_MODE.preview && isMobile && !last" class="handle-drag-and-drop-wrapper down" (click)="moveItem(i, i+1)">
                <i class="icon-down"></i>
              </div>
              <div *ngIf="mode !== AGREEMENT_FORM_MODE.preview && !isMobile" class="handle-drag-and-drop-wrapper">
                <i class="icon-drag handle-drag-and-drop"></i>
              </div>
            </div>
          </div>
        <div class="dinamic-form-controls" *ngIf="mode !== AGREEMENT_FORM_MODE.preview">
          <div class="dinamic-form-control">
            <div class="icon-wrapper" (click)="addDropdown()">
              <i class="icon-plus-in-circle"></i>
            </div>
            <p>{{ 'REQUISITION_FORM.ADD_DROPDOWN' | translate }}</p>
          </div>
          <div class="dinamic-form-control">
            <div class="icon-wrapper" (click)="addTextField()">
              <i class="icon-plus-in-circle"></i>
            </div>
            <p>{{ 'REQUISITION_FORM.ADD_TEXT_FIELD' | translate }}</p>
          </div>
          <div class="dinamic-form-control">
            <div class="icon-wrapper" (click)="addSignature()">
              <i class="icon-plus-in-circle"></i>
            </div>
            <p>{{ 'AGREEMENT_FORM.ADD_SIGNATURE' | translate }}</p>
          </div>
          <div class="dinamic-form-control">
            <div class="icon-wrapper" (click)="addInfoField()">
              <i class="icon-plus-in-circle"></i>
            </div>
            <p>{{ 'AGREEMENT_FORM.ADD_INFO' | translate }}</p>
          </div>
          <div class="dinamic-form-error" *ngIf="dinamicForm.invalid && dinamicForm.touched && !dinamicForm.controls.length">{{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="panel button-wrapper" *ngIf="mode !== AGREEMENT_FORM_MODE.preview">
      <button type="button"
      type="submit"
      class="btn btn-primary"
      (click)="submit()">
      {{'BUTTONS.SAVE' | translate}}
      </button>
      <button type="button"
      class="btn btn-primary"
      (click)="openPreviewAgreementModal()">
      {{'BUTTONS.PREVIEW' | translate}}
      </button>
    </div>

  </form>
</section>
